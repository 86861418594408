import * as React from 'react'
import Layout from '../components/Layout'
import { Link } from 'gatsby'
import Surprised from '../animations/surprised'

function NotFoundPage() {
  return (
    <Layout>
      <article>
        <div className="mx-auto my-responsive-xl-48 max-w-[16rem]">
          <Surprised />
        </div>
        <section className="space-y-responsive-xl-32 text-center">
          <h1>Page Not Found</h1>
          <p>Sorry, we couldn’t find what you were looking for.</p>
          <Link to="/" className="btn btn-primary">
            Go home
          </Link>
        </section>
      </article>
    </Layout>
  )
}

export default NotFoundPage
